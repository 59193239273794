<template>
  <div class="div-block-7 article-content-subpage">
    <div class="div-block-14">
      <div class="block-headline block-headline-news">Neuigkeiten</div>
    </div>
    <div v-if="loading">
      <p>Lade…</p>
    </div>
    <div v-else v-for="post in posts" :key="post.id">
      <PostFull :post="post" />
    </div>
    <button
      class="button basic uppercase fluid centered btn-loadmore"
      @click="loadMorePosts"
    >
      Ältere Neuigkeiten laden
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PostFull from "@/components/posts/PostFull";

export default {
  name: "news",
  metaInfo: {
    title: "Neuigkeiten",
  },
  components: {
    PostFull,
  },
  computed: {
    ...mapGetters({
      loading: "post/loading",
      posts: "post/list",
    }),
  },
  methods: {
    async loadMorePosts() {
      var articles = document.getElementsByClassName("block-article");
      var lastArticle =
        articles[articles.length - 1].getAttribute("data-post-id");

      // Load next posts
      await this.$store.dispatch("post/getNextPosts");

      // // Scroll back to last element in the list
      setTimeout(function () {
        document
          .querySelector(`[data-post-id="${lastArticle}"]`)
          .scrollIntoView();
      }, 300);
    },
  },
};
</script>

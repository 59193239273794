<template>
  <div class="collection-item-3 w-dyn-item w-col w-col-4" v-if="post">
    <router-link
      v-if="post.id"
      :to="{ name: 'post', params: { id: post.id, slug: post.slug } }"
      class="link-block-2 w-inline-block"
      :style="{
        'background-image': `url(${backgroundImgUrl})`,
      }"
    >
      <div class="div-block-20">
        <div class="div-block-21">
          <h4 class="heading-3 heading--card">{{ post.title }}</h4>
        </div>
      </div>
    </router-link>
    <!-- <div class="text-block-date-info text-meta" v-if="post.created">
      {{ post.created.seconds | moment("DD. MMMM YYYY") }}
    </div> -->
  </div>
</template>

<script>
export default {
  name: "postCard",
  props: ["post"],
  computed: {
    backgroundImgUrl() {
      if (this.post && this.post.imageUrl) {
        return this.post.imageUrl;
      }
      return require("@/assets/images/thumbnail.jpg");
    },
  },
};
</script>

<template>
  <div>
    <!-- [START] content -->
    <div class="div-block-7 article-content-subpage" v-if="post">
      <div class="ui breadcrumb">
        <i
          class="left angle icon divider"
          style="margin-left: -4px; margin-top: 1px"
        ></i>
        <router-link :to="{ name: 'home' }" class="section text-meta"
          >Alle Neuigkeiten</router-link
        >
      </div>
      <div class="div-block-14">
        <h1 class="heading-link heading-subpage">{{ post.title }}</h1>
      </div>
      <div class="block-article">
        <img
          v-if="post.imageUrl"
          :src="post.imageUrl"
          :alt="post.title"
          class="image-article"
        />
        <div class="block-article-content">
          <div class="w-richtext" v-html="post.content"></div>
        </div>
      </div>
      <p class="text-meta" style="text-align: center">
        Veröffentlicht am
        {{ post.created.seconds | moment("DD. MMMM YYYY") }}
        <span v-if="post.authorRef && post.authorRef.fullName"
          >von {{ post.authorRef.fullName }}</span
        >
      </p>
    </div>
    <!-- [END] content -->
    <!-- [START] Releated Posts -->
    <RelatedPosts :post="post" />
    <!-- [END] Releated Posts -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RelatedPosts from "@/components/posts/RelatedPosts";

export default {
  name: "post",
  components: {
    RelatedPosts,
  },
  data() {
    return {
      post: null,
    };
  },
  asyncComputed: {
    async post() {
      const postSlug = this.$route.params.slug;
      let post = this.postBySlug(postSlug);
      if (!post) {
        return this.$store.dispatch("post/getById", {
          postId: this.$route.params.id,
        });
      } else {
        return post;
      }
    },
  },
  computed: {
    ...mapGetters({
      postBySlug: "post/postBySlug",
    }),
  },
  metaInfo() {
    if (!this.post || !this.post.title) {
      return {
        title: "Neuigkeit",
      };
    }
    return {
      title: this.post.title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.post.excerpt,
        },
        {
          vmid: "og:type",
          name: "og:type",
          content: "article",
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: this.post.title,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: this.post.excerpt,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: this.post.imageUrl,
        },
        {
          vmid: "og:url",
          name: "og:url",
          content: `https://www.skiklub-oestertal.de${this.$route.path}`,
        },
      ],
    };
  },
};
</script>

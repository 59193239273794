<template>
  <div class="section-related-content">
    <div class="block-headline block-headline-news headline-related-content">
      Weitere Neuigkeiten
    </div>
    <div class="w-dyn-list">
      <div class="w-dyn-items w-row">
        <div v-for="post in posts" :key="post.id">
          <PostCard :post="post" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PostCard from "@/components/posts/PostCard";

export default {
  name: "relatedPosts",
  props: ["post"],
  components: {
    PostCard,
  },
  computed: {
    ...mapGetters({
      relatedPostsBySlug: "post/relatedPostsBySlug",
    }),
    posts() {
      if (!this.post) return;
      return this.relatedPostsBySlug(this.post.slug);
    },
  },
};
</script>
